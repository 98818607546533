import React from "react"
import { graphql } from "gatsby"
import FrontLayout from "../components/FrontLayout"
import { Box, Text } from "@chakra-ui/react"

const Post = ({ data }) => {
  return (
    <FrontLayout>
      <Box>
        <Text fontSize="3xl" fontWeight="bold" my={5}>
          {data.wpPost.title}
        </Text>
        <Text my={5}>{data.wpPost.date}</Text>
        <Box my={6}>
          <div dangerouslySetInnerHTML={{ __html: data.wpPost.content }} />
        </Box>
      </Box>
    </FrontLayout>
  )
}

export default Post

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      date(formatString: "YYYY.MM.DD")
      title
      content
    }
  }
`
